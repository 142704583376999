<template>
    <div class="box flex flex-column">
        <Header type="expert" :navList="navList" :typeList="typeList" :navCurrentId="navCurrentId" :logo="logoUrl"></Header>
        <div class="container mobile-bread mt15 flex x-left">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/' }">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <a class="color999" href="javascript:;" @click="$router.back(-1)">知名专家</a>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '' }">专家详情</router-link>
                </a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="container mt20">
            <a-spin :spinning="loading" tip="Loading...">
                <div class="person-item">
                    <img class="imgbg-item" src="~@/assets/images/person-detail-bg.png" alt=""></img>
                    <div class="avatar-item">
                        <img :src="userDetail.avatar | urlFilter(250, 'avatar') || require('@/assets/images/person-default.png')" alt="">
                        <div class="follow-btn cursor colorfff t-c fs12 strong" v-if="userDetail.fans_status != 1" @click="handleFollowEvent(userDetail.user_id,true)">+ 关注</div>
                        <div class="follow-btn follow-btn-none cursor colorfff t-c fs12 strong" v-else @click="handleFollowEvent(userDetail.user_id)">取消关注</div>
                        <div class="mt20 color333 fs18 strong">{{ userDetail.real_name }}</div>
                        <div class="mt5 color666 fs14">{{ userDetail.org_cnname }}</div>
                    </div>
                    <div class="num-list flex x-around y-center">
                        <!-- <span class="flex-column">
                            <span class="fs22 strong" style="color:#1E51C9;">{{ userDetail.meeting_num || 0 }}</span>
                            <span class="mt5 fs14 color333">参会记录</span>
                        </span>
                        <span class="line"></span> -->
                        <span class="flex-column">
                            <span class="fs22 strong color333">{{ userDetail.video_num || 0 }}</span>
                            <span class="mt5 fs14 color333">讲课视频</span>
                        </span>
                        <span class="line"></span>
                        <span class="flex-column">
                            <span class="fs22 strong color333">{{ userDetail.abstract_num || 0 }}</span>
                            <span class="mt5 fs14 color333">论文摘要</span>
                        </span>
                        <span class="line"></span>
                        <span class="flex-column">
                            <span class="fs22 strong" style="color:#C91E1E;">{{ userDetail.fans_num || 0 }}</span>
                            <span class="mt5 fs14 color333">Ta的粉丝</span>
                        </span>
                        <span class="line"></span>
                        <span class="flex-column">
                            <span class="fs22 strong" style="color:#E78F21;">{{ userDetail.follow_num || 0 }}</span>
                            <span class="mt5 fs14 color333">Ta的关注</span>
                        </span>
                    </div>
                </div>
                <div :class="['mt30', 'content-list', userJobList.length ? 'six' : 'five']">
                    <a-tabs class="info-tab-pane" v-model:activeKey="activeKey" @change="handleTabChangeEvent">
                        <a-tab-pane key="1" tab="专家简介">
                            <div class="pt30 pb20 pl20 mobile-tab-content pr20 t-l" v-if="userDetail.resume" v-html="userDetail.resume">
                            </div>
                            <a-empty class="mt30 mb30" v-if="!userDetail.resume && !loading" />
                        </a-tab-pane>
                        <a-tab-pane key="6" tab="学术任职" id="job-tab-box" v-if="userJobList.length">
                            <div>
                                <div class="resume flex-item t-l">
                                    <a-tabs>
                                        <a-tab-pane key="1" :tab="item.title + '任职'" v-for="(item,index) in userJobList" :key="index">
                                            <div class="resume-items">
                                                <div class="resume-item flex x-left" v-for="(data,idx) in item.list" @click="handleToJobDtaPage(data)">
                                                    <div class="time">
                                                        <span v-if="data.BeginTime && data.EndTime">{{ data.BeginTime | formatDate('yyyy年') }} - {{ data.EndTime | formatDate('yyyy年') }}</span>
                                                    </div>
                                                    <div class="surname">
                                                        {{item.title}}{{data.b_name}}第{{data.Sequence}}届{{data.t_name}}
                                                    </div>
                                                    <div class="position">
                                                        {{ data.position }}
                                                    </div>
                                                </div>
                                            </div>
                                        </a-tab-pane>
                                    </a-tabs>
                                </div>
<!--                                <a-empty class="mt30 mb30" v-if="!userJobList.length && !loading" />-->
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="讲课视频">
                            <div class="pl20 pr20 mb30 mobile-tab-content list-box flex x-left flex-wrap" v-if="videoList.length">
                                <div class="list-box-item" :class="[{ 'ml20': index % 5 != 0 }, 'mt20']" v-for="(item, index) in videoList" :key="index">
                                    <vertical-media-item type="2" gap="0" width="216px" height="122px" :item="{ id: item.id, img: item.default_pic_url, title: item.title, name: item.real_name, org: item.org_cnname }" @clickEvent="videoJumpDetail(item.id)"></vertical-media-item>
                                </div>
                            </div>
                            <a-empty class="mt30 mb20" v-if="!videoList.length && !loading" />
                            <div class="t-c mt30 mb20" v-if="videoCount > 0">
                                <a-pagination :total="videoCount" v-model:current="videoParams.page" :page-size="videoParams.PageSize" show-quick-jumper @change="handleVideoChangeEvent" :hideOnSinglePage="true" />
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="期刊论文">
                            <template v-if="paperSource == 1">
                                <div class="pb10 mobile-tab-content t-l mt20 mb20 pl20 pr20" v-if="paperList.length">
                                    <div class="t-l mt20 mb20 pb20 cursor" style="border-bottom:1px solid #eee;" v-for="(item, index) in paperList" :key="index" @click="handlePaperDetailEvent(item.id)">
                                        <span class="fs16 color333 strong row-2 mb10" v-if="item.title">{{ item.title
}}</span>
                                        <span class="color999 fs14 row-2 mb10" v-if="item.abstract">{{ item.abstract
}}</span>
                                        <span class="color999 fs14 row-2 mb10" v-if="item.abstract">作者：{{
        strToArr(item.author,
            ',')
}}</span>
                                        <span class="flex x-left">
                                            <span class="color999 fs14 row-1 mr10" v-if="item.year">{{ item.year
}}年</span>
                                            <span class="color999 fs14 row-1 mr10" v-if="item.source">《{{ item.source
}}》</span>
                                        </span>
                                    </div>
                                </div>
                                <a-empty class="mt30 mb20" v-if="!paperList.length && !loading" />
                                <div class="t-c mb20" v-if="paperParams.count > 0">
                                    <a-pagination :total="paperParams.count" v-model:current="paperParams.page" :page-size="paperParams.pageSize" show-quick-jumper @change="handlePaperChangeEvent" :hideOnSinglePage="true" />
                                </div>
                            </template>
                            <template v-if="paperSource == 0">
                                <div class="interview-list mobile-tab-content pt30 pl20 pr20 mb20" v-if="paperList.length">
                                    <div v-for="(item, index) in paperList" :key="index" class="paper-list cursor pb20 t-l" :class="{ 'mt20': index != 0 }" style="border-bottom:1px solid #eee;" @click="goArticleDetail(item.id)">
                                        <p class="fs16 color333 strong mb10">{{ item.title }}</p>
                                        <p v-if="item.keywords" class="fs12 color333 mb10">关键词：{{ item.keywords }}</p>
                                        <div class="flex x-between color999 fs12">
                                            <span v-if="item.cn_title" class="fs12 color333 mb5 row-1">来源：{{
        item.cn_title
}}</span>
                                            <span class="ml10 t-r" style="width:100px;"><i class="med med-yanjing fs14 mr5"></i>{{ item.hits }}</span>
                                        </div>
                                    </div>
                                </div>
                                <a-empty class="mt30 mb20" v-if="!paperList.length && !loading" />
                                <div class="t-c mb20" v-if="paperCount > 0">
                                    <a-pagination :total="paperCount" v-model:current="newPaperParams.page" :page-size="newPaperParams.limit" show-quick-jumper @change="handleNewPaperChangeEvent" :hideOnSinglePage="true" />
                                </div>
                            </template>

                        </a-tab-pane>
                        <a-tab-pane key="4" tab="大咖动态">
                            <div v-if="newsList.length" class="pl20 mobile-tab-content pr20 pt30">
                                <div v-for="(item, index) in newsList" :key="index" class="news-list" :class="{ 'mt20': index != 0 }">
                                    <hot-topic-item path="/infomation-detail" :type="item.type" :item="item" containerWidth="100%" class="news-item">
                                    </hot-topic-item>
                                </div>
                            </div>
                            <a-empty class="mt30 mb20" v-if="!newsList.length && !loading" />
                            <div class="t-c mt30 mb20" v-if="newsCount > 0">
                                <a-pagination :total="newsCount" v-model:current="newsParams.page" :page-size="newsParams.limit" show-quick-jumper @change="handleNewsChangeEvent" :hideOnSinglePage="true" />
                            </div>
                        </a-tab-pane>
                        <!-- <a-tab-pane key="5" tab="参会记录">
                            <div class="meeting-list t-l mobile-tab-content mt30 pl20 pr20" v-if="userDetail.meeting && userDetail.meeting.length">
                                <div class="mb10" v-for="(item, index) in userDetail.meeting" :key="index">
                                    <span class="meeting-year pt5 pb5 pl20 pr20 colorfff mt10">{{ item.title }} <i class="med med-sanjiaoxia"></i></span>
                                    <div class="meeting-content flex x-left mt20" v-for="(valItem, valIndex) in item.value" :key="valIndex">
                                        <span class="circle"></span>
                                        <span class="meeting-time fs16 ml5 mr5 pl15 pr15 strong">{{
                                                dateFormat(valItem.start_time)
                                        }}</span>
                                        <div class="ml10 cursor" @click="meetJumpDetail('', valItem.meeting_id)">
                                            <div class="strong color333 fs16 mb10">{{ valItem.cn_title }}</div>
                                            <div class="color999 fs14 mb5" v-if="valItem.venue_name_cn"><i class="med med--didian mr5 fs14"></i>{{ valItem.venue_name_cn }}
                                            </div>
                                            <div v-if="valItem.role.length">
                                                <i class="med med-qizi mr5 fs14"></i>
                                                <span class="mr10 fs14 color999" v-for="(role_item, role_index) in valItem.role">
                                                    <span>{{ role_item.typename_cn }}</span>
                                                    <span style="color:red;">[{{ role_item.count }}]</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a-empty class="mt30 mb20" v-if="!userDetail.meeting" />
                        </a-tab-pane> -->
                        <!-- <a-tab-pane key="7" tab="相册">
                            <div class="album-box flex x-left flex-wrap mb20">
                                <router-link
                                    :class="['album-item t-l strong color333 row-1', { 'mr0': (index + 1) % 5 == 0 }]"
                                    v-for="(item, index) in albumList" :key="index" to="">
                                    <div class="album-image flex flex-wrap mb10">
                                        <img class="album-image-img" :src="item.pic_index | urlFilter(250)" alt="" />
                                    </div>
                                    <div>{{ item.album_name }}</div>
                                    <div class="flex x-between">
                                        {{  }}
                                    </div>
                                </router-link>
                            </div>
                        </a-tab-pane> -->
                    </a-tabs>
                </div>
            </a-spin>
        </div>
        <Footer />
    </div>
</template>

<script>
import { expertNavList, exportLogo } from "@/config/const";
import Header from '@/components/layout/layout-header';
import Footer from '@/components/layout/layout-footer';
import verticalMediaItem from '@/components/vertical-media-item.vue';
import hotTopicItem from '@/components/hot-topic-item.vue';
import { videoJumpDetail, meetJumpDetail } from '@/utils/jumpPageMethods';
import { isObject } from "@/utils/judgeDataType";

export default {
    name: "PersonDetail",
    components: {
        Header,
        Footer,
        verticalMediaItem,
        hotTopicItem,
    },
    data() {
        return {
            logoUrl: exportLogo,
            navList: expertNavList,//顶部菜单
            navCurrentId: 2,//当前页面菜单对应下标
            loading: true,
            user_id: this.$route.query.uid, //用户user_id
            userDetail: {}, //用户基本信息
            activeKey: '2',
            paperSource: 1,
            videoList: [], //讲课视频列表
            videoCount: 0,
            allPaperList: [],
            paperList: [], //期刊论文列表
            newsList: [], //大咖动态列表
            newsCount: 0, //大咖动态总数
            albumList: [], //相册列表
            albumCount: 0, //相册总数
            videoParams: {
                page: 1,
                PageSize: 10,
                no_count: false,
            }, //讲课视频列表参数
            newsParams: {
                is_expert: 1,
                limit: 10,
                page: 1,
                type: 6,
            }, //大咖动态列表参数
            albumParams: {
                page: 1,
                limit: 20,
            }, //相册列表参数
            paperParams: {
                page: 1,
                pageSize: 10,
                count: 0
            }, //期刊论文分页参数
            newPaperParams: {
                page: 1,
                limit: 10,
            },
            paperCount: 0,
            typeList: [
                { id: 6, name: '全部', path: '/expert/user-search',selected:true },
                { id: 7, name: '院士', path: '/expert/ys-mien' },
                { id: 8, name: '主委', path: '/expert/committee' },
            ],
            userJobList: [],//任职记录
        }
    },
    created() {
        this.pageInit();
        this.getUserVideo();
        this.getUserJobList();
    },
    // 缓存页面二次进入的钩子函数
    activated() {
        // this.pageInit();
    },
    methods: {
        videoJumpDetail,
        meetJumpDetail,
        isObject,
        // 作者转数组
        strToArr(str, s, len, r) {
            var arr = str ? (str.split(s || ',')) : [];
            return arr.join(r || '，')
        },
        dateFormat(date) {
            if (date) { return date.slice(5, 10).replace('-', '/'); }
            return '';
        },
        // 获取用户信息
        getUserInfo() {
            return this.request.post('ExpertsInfoNew', { experts_uid: this.user_id })
        },
        // 讲课视频列表
        getUserVideo() {
            this.request.get('GetVideoListNew', {
                ...this.videoParams,
                experts_uid: this.user_id
            }).then(res => {
                // 讲课视频
                this.videoList = res.data.list
                this.videoCount = parseInt(res.data.count)
            })
        },
        // 前往任职详情页
        handleToJobDtaPage(data){
            let { CouncilID , BranchId , PeriodID } = data;
            this.$router.push({
                path : '/job-warehouse/rollCall',
                query : {
                    CouncilID,
                    BranchId,
                    PeriodID
                }
            })
        },
        // 期刊论文列表
        getPaperList() {
            this.loading = true
            this.request.post('ExpertsPaperList', { experts_uid: this.user_id }).then(res => {
                if (res.data.length) {
                    let { page, pageSize } = this.paperParams
                    this.allPaperList = res.data
                    this.paperParams.count = res.data.length
                    this.paperList = this.allPaperList.slice((page - 1) * pageSize, page * pageSize);
                } else {
                    this.paperSource = 0
                    this.request.post('GetMeetingPaper', {
                        ...this.newPaperParams,
                        experts_uid: this.user_id
                    }).then(res => {
                        this.paperList = res.data.list
                        this.paperCount = parseInt(res.data.count)
                    })
                }


                this.loading = false
            })
        },
        // 大咖动态
        getNewsList() {
            this.loading = true
            this.request.post('GetNews', {
                ...this.newsParams,
                expert_user_id: this.user_id
            }).then(res => {
                this.newsCount = parseInt(res.data.count)
                this.newsList = res.data.list
                this.loading = false
            })
        },
        // 相册
        getAlbumList() {
            this.request.post('GetAlbumList', {
                ...this.albumParams,
                experts_uid: this.user_id
            }).then(res => {
                this.albumCount = parseInt(res.data.list.count)
                this.albumList = res.data.list.list
            })
        },
        goArticleDetail(id) {
            this.$router.push({
                path: '/article-detail',
                query: {
                    id
                }
            })
        },
        // 期刊论文分页
        handlePaperChangeEvent(e) {
            this.paperParams.page = e
            let { page, pageSize } = this.paperParams
            this.paperList = this.allPaperList.slice(page * pageSize - pageSize, page * pageSize)
        },
        // 大咖动态分页
        handleNewsChangeEvent(e) {
            this.newsParams.page = e
            this.getNewsList()
        },
        handleVideoChangeEvent(e) {
            this.videoParams.page = e
            this.getUserVideo()
        },
        handleNewPaperChangeEvent(e) {
            this.newPaperParams.page = e
            this.getPaperList()
        },
        // tab切换
        handleTabChangeEvent(e) {
            this.activeKey = e
            if (e == 3 && !this.paperList.length) {
                this.getPaperList()
            }
            if (e == 4 && !this.newsList.length) {
                this.getNewsList()
            }
            if( e == 6 && !this.userJobList.length){
                this.getUserJobList()
            }
            // if (e == 5 && !this.albumList.length) {
            //     this.getAlbumList()
            // }
        },
        // 关注
        handleFollowEvent(userid,is_focus) {
            this.request.post('FollowExperts', { experts_uid: userid }).then(res => {
                if (res.data.code == 200) {
                    this.userDetail.fans_status = this.userDetail.fans_status == 0 ? 1 : 0;
                    // 关注成功加积分
                    if(is_focus) {
                        this.request.post('AddPoint', { task_id: 3, type_id: userid },{ hideModal: true }).then(res => {}).catch(err => {})
                    }
                }
            })
        },
        // 期刊论文详情
        handlePaperDetailEvent(id) {
            this.$router.push({
                path: '/paper-detail',
                query: {
                    id: id,
                }
            })
        },
        getUserJobList(){
            this.loading = true
            this.request.post('UserJobDetails',{ experts_uid: this.user_id }).then(res=>{
                let result = [];
                // 遍历res.data.data对象
                for (let key in res.data.data) {
                    // 将对象转为数组
                    result.push({
                        title : key,
                        list : res.data.data[key]
                    });
                }
                this.userJobList = result;
                this.loading = false;
            })
        },
        pageInit() {
            this.loading = true
            let requestArr = [
                this.getUserInfo(), //专家信息
            ]
            Promise.all(requestArr).then(res => {
                if(res[0].data){
                    if (!this.isObject(res[0].data?.meeting)) {
                        res[0].data.meeting = null;
                    };
                    if (res[0].data?.meeting) {
                        let meeting = [], meetingArr = Object.keys(res[0].data.meeting || {}).sort((a, b) => b - a);
                        for (let i = 0, l = meetingArr.length; i < l; i++) {
                            const k = meetingArr[i]
                            meeting.push({
                                title: k,
                                value: res[0].data.meeting[k]
                            })
                        }
                        res[0].data.meeting = meeting;
                    }

                    // 用户基本信息
                    this.userDetail = res[0].data
                }
                
                // 讲课视频
                this.loading = false;
            })
        }
    }
}
</script>

<style scoped lang="less">
.person-item {
    position: relative;
    width: 100%;
    height: 385px;

    .cursor {
        cursor: pointer;
    }

    .imgbg-item {
        position: absolute;
        left: 0;
        right: 0;
        height: 330px;
        object-fit: fill;
        object-position: center top;

        width: 100%;
        top: 70px;
    }

    .avatar-item {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;

        img {
            width: 140px;
            height: 140px;
            border-radius: 50%;
            object-fit: cover;
            object-position: top;
        }

        .follow-btn {
            width: 92px;
            height: 37px;
            line-height: 37px;
            border-radius: 20px;
            background: linear-gradient(to right, #5f96e6, #1e51c9);
            box-shadow: 0 1px 2px 0 rgba(30, 81, 201, 0.1);
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 116px;
        }

        .follow-btn-none {
            background: #eff3fb;
            border: 1px solid #ddd;
            color: #999;
        }
    }

    .num-list {
        width: 1040px;
        height: 90px;
        background: #ffffff;
        box-shadow: 0px 2px 11px 0px rgba(44, 96, 207, 0.15);
        border-radius: 8px;
        border: 1px solid #f5f5f5;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 25px;
        padding: 0 30px;
        box-sizing: border-box;

        .line {
            width: 1px;
            height: 40px;
            background: #f5f5f5;
        }
    }
}

.content-list {
    background: #ffffff;
    border-radius: 10px 10px 0px 0px;

    .cursor {
        cursor: pointer;
    }

    /deep/.info-tab-pane {
        box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.08);
    }

    /deep/.ant-tabs-bar {
        border-bottom: 1px solid #f5f5f5;
        background: #fafbff;
        margin: 0;
    }

    /deep/.ant-tabs-nav-container {
        height: 80px;
    }

    /deep/.ant-tabs-tab {
        height: 80px;
        line-height: 80px;
        padding: 0 16px;
        font-size: 18px;
        color: #333333;
        font-weight: bold;
    }

    /deep/.ant-tabs-tab-active {
        color: #1e51c9;
    }

    /deep/.ant-tabs-ink-bar {
        background: #1e51c9;
    }

    /deep/.ant-tabs-nav {
        width: 100%;

        div {
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
    }

    /deep/.ant-tabs-ink-bar-animated {
        width: 80px !important;
        left: 67px; // 67
    }
    &.six /deep/.ant-tabs-ink-bar-animated{
        left: 47px;
    }

    .news-list {
        border-bottom: 1px solid #eee;

        &:last-child {
            border: 0;
        }
    }

    .news-item {
        /deep/.report-img {
            justify-content: flex-start;
        }

        /deep/.report-img-box {
            margin-right: 20px;
        }
    }

    .meeting-list {
        .meeting-year {
            background-color: #4668e0;
            border-radius: 20px;
            display: inline-block;
        }

        .meeting-content {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: 3px;
                top: 22px;
                bottom: -26px;
                border-left: 1px solid #d7e0f1;
                border-left-width: 1px;
                border-left-style: solid;
                border-left-color: rgb(215, 224, 241);
            }

            .circle {
                width: 8px;
                height: 8px;
                background-color: #ffffff;
                border: 2px solid #4668e0;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border-radius: 8px;
                margin-top: 11px;
            }

            .meeting-time {
                background-color: rgba(70, 103, 224, 0.1);
                line-height: 25px;
                height: 25px;
                color: #4668e0;
                border-radius: 20px;
            }

            &:last-child {
                &:before {
                    border-left: 0;
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .list-box {
        &-item {
            .vertical-media-item {
                width: 100% !important;
            }
            width: calc(33% - 10px);
            margin-left: 16px;
            &:nth-of-type(3n + 1) {
                margin-left: 0;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .list-box {
        &-item {
            width: calc(50% - 10px);
            margin-left: 16px !important;
            &:nth-of-type(2n + 1) {
                margin-left: 0 !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .mobile-bread {
        padding: 0 10px;
        display: flex;
        flex-wrap: wrap;
        text-align: left;
    }
    .person-item {
        height: 350px;
        .num-list {
            width: 95%;
            padding: 0 10px;
            bottom: 20px;
            .fs14 {
                font-size: 12px;
            }
        }
        .imgbg-item {
            height: 280px;
        }
    }
    .content-list {
        margin-top: 20px;
        /deep/.ant-tabs-nav-container {
            height: 1.2rem;
            .ant-tabs-tab {
                line-height: 1.2rem;
                height: 1.2rem;
                font-size: 14px;
                padding: 0;
                margin: 0;
                & > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .ant-tabs-ink-bar {
                display: none !important;
            }
        }
    }
    .mobile-tab-content {
        padding: 10px;
        margin-top: 0;
    }
    .news-list {
        padding-bottom: 20px;
    }
}

#job-tab-box{
    padding: 30px;
    /deep/.ant-tabs-nav {
        width: auto;

        div {
            width: auto;
            display: inline-block;
            justify-content: space-around;
        }
    }

    /deep/.ant-tabs-tab {
        height: 100%;
        line-height: 1;
        padding: 12px 16px;
        font-size: 16px;
        color: #333333;
    }

    /deep/.ant-tabs-bar {
        border-bottom: 1px solid #f5f5f5;
        background: #ffffff;
        margin: 0;
    }

    /deep/.ant-tabs-nav-container {
        height: 40px;
    }
    /deep/ .ant-tabs-nav .ant-tabs-tab-active{
        color: #1890ff;
        font-weight: 400;
    }
    /deep/ .ant-tabs-ink-bar{
        background-color: #1890ff;
        width: 65px;
    }
    /deep/.ant-tabs-ink-bar-animated {
        width: 80px !important;
        left: 26px; // 67
    }

    .resume{
        .resume-items{
            height: 158px;
            overflow-y: auto;
            padding-top: 20px;
            .resume-item{
                padding-left: 30px;
                margin-bottom: 18px;
                cursor: pointer;
                & > div{
                    margin-right: 50px;
                }
                .time{
                    min-width: 140px;
                }
                .surname{
                    min-width: 140px;
                }
                .position{
                    min-width: 120px;
                }
            }
        }
    }
}
</style>